import React from 'react';
import { ButtonProps } from '../../types/types';

const NavBarButton = (props: ButtonProps) => {
  return (
    <button
      className='bg-transparent hover:bg-white text-white font-semibold hover:text-midnightBlue py-2 px-4 border border-white hover:border-transparent rounded-full active:scale-95'
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default NavBarButton;
