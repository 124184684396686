import React, { useEffect, useState } from 'react';
import { Box, Snackbar, TextField } from '@mui/material';
import { sendUserMessage } from '../services/firebase';
import { heading1 } from '../styles/tailwindStyles';
import RoundedButton from '../components/buttons/roundedButton';
import { isValidEmail } from '../helpers/helpers';

const Support = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [nameTextFieldError, setNameTextFieldError] = useState(false);
  const [emailTextFieldError, setEmailTextFieldError] = useState(false);
  const [messageTextFieldError, setMessageTextFieldError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (name !== '') {
      setNameTextFieldError(false);
    }
    if (email !== '') {
      setEmailTextFieldError(false);
    }
    if (message !== '') {
      setMessageTextFieldError(false);
    }
  }, [name, email, message]);

  const handleTextFieldError = () => {
    if (name === '') {
      setNameTextFieldError(true);
    }
    if (!isValidEmail(email)) {
      setEmailTextFieldError(true);
    }
    if (message === '') {
      setMessageTextFieldError(true);
    }
  }

  const handleSubmit = async () => {
    if (name === '' || message === '' || !isValidEmail(email)) {
      handleTextFieldError();
      return;
    }
    sendUserMessage({
      to: ['riley@progresso.dev'],
      message: {
        subject: 'New Support Message',
        html: `<p>Name: ${name}</p><p>Email: ${email}</p><p>Message: ${message}</p>`,
      },
    })
      .then(() => {
        setName('');
        setEmail('');
        setMessage('');
        setSnackbarMessage(
          'Message Sent Successfully! We will get back to you by email shortly!'
        );
        setOpen(true);
      })
      .catch((error) => {
        setSnackbarMessage(
          'Error sending message. Please try again. Error: ' + error
        );
      });
  };

  return (
    <div className='support-page'>
      <Box
        display='flex'
        flexDirection='column'
        gap={5}
        my={4}
        minHeight='100vh'
        maxWidth='50vw'
        margin='0 auto'
      >
        <Box height={1}/>
        <h1 className={heading1}>Contact Us</h1>
        <TextField
          className='pb-4'
          error={nameTextFieldError}
          helperText={nameTextFieldError ? 'Please enter a name' : ''}
          name='name'
          label='Name'
          type='text'
          value={name}
          onChange={(n) => setName(n.target.value)}
        />
        <TextField
          error={emailTextFieldError}
          helperText={emailTextFieldError ? 'Invalid email' : ''}
          name='email'
          label='Email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          error={messageTextFieldError}
          helperText={messageTextFieldError ? 'Please enter a message' : ''}
          name='message'
          label='Message'
          type='text'
          multiline
          rows={10}
          value={message}
          onChange={(m) => setMessage(m.target.value)}
        />
        <RoundedButton text='Submit' onClick={handleSubmit} />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setOpen(false);
        }}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Support;
