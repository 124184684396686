// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore"
import { UserMessage } from "../types/types";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCk-tO8m1IYBA5rE4nq3je9WvLAPPp43LM",
  authDomain: "progresso-site.firebaseapp.com",
  projectId: "progresso-site",
  storageBucket: "progresso-site.appspot.com",
  messagingSenderId: "399056580529",
  appId: "1:399056580529:web:726f13b0264704d2c0d790"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const sendUserMessage = async (userMessage: UserMessage) => {
  await addDoc(collection(db, 'mail'), userMessage)
}

export { db };