import React, { useState } from 'react';
import progressoIconLogo from '../assets/navBarIcon.png';
import progressoTextLogo from '../assets/textlogoTransparent.png';
import { useNavigate } from 'react-router-dom';
import NavBarButton from './buttons/NavBarButton';
import { Drawer, IconButton } from '@mui/material';
import HamburgerIcon from '../assets/menu-hamburger-white.svg';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const buildSideMenu = () => {
    return (
      <div className='h-full bg-black p-5'>
        <NavBarButton
          text='Contact Us'
          onClick={() => {
            setMenuOpen(false);
            navigate('/support');
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className='flex flex-row justify-between items-center bg-black w-full navbar pr-8'>
        <div
          className='max-h-full flex items-center cursor-pointer flex-grow'
          onClick={() => navigate('/')}
        >
          <img
            rel='icon'
            src={progressoTextLogo}
            alt='progresso big logo with text'
            className='navbar-icon hidden md:block ml-4'
          />
          <img
            rel='icon'
            src={progressoIconLogo}
            alt='progresso small icon logo'
            className='navbar-icon block md:hidden'
          />
        </div>
        <div className='hidden md:block'>
          <NavBarButton
            text='Contact Us'
            onClick={() => navigate('/support')}
          />
        </div>
        <div className='block md:hidden'>
          <IconButton
            color='info'
            aria-label='open menu'
            onClick={() => setMenuOpen(true)}
          >
            <img src={HamburgerIcon} alt='Open Navigation Menu'/>
          </IconButton>
        </div>
        <Drawer
          anchor='right'
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          {buildSideMenu()}
        </Drawer>
      </div>
    </>
  );
};

export default NavBar;
