import React from 'react';
import { ButtonProps } from '../../types/types';

// Changed name
const RoundedButton = (props: ButtonProps) => {
  return (
    <button
      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default RoundedButton;
