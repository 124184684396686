import { Box } from '@mui/material';
import React from 'react';
import textLogo from '../assets/textlogo.png';

const HomePage = () => {
  return (
    <div className='home-container'>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box height='20vh' />
        <div className='home-logo-container'>
          <img className='home-logo' src={textLogo} alt='progresso' />
        </div>
      </Box>
    </div>
  );
};

export default HomePage;
