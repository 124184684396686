import { Route, Routes } from 'react-router-dom';
import './App.css';
import Support from './pages/Support';
import HomePage from './pages/HomePage';
import LiftPrivacyPolicy from './pages/LiftPrivacyPolicy';
import React from 'react';
import NavBar from './components/NavBar';

function App() {
  return (
    <div className='App'>
      <NavBar />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/support' element={<Support />} />
          <Route path='/lift/privacy-policy' element={<LiftPrivacyPolicy />} />
        </Routes>
    </div>
  );
}

export default App;
